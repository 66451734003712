const EnvironmentName = "test2022";

const config = {
  test2022: {
    WaegApiBaseUrl: "http://localhost:3000",
  },
  test2022: {
    WaegApiBaseUrl: "https://devapi.daeg.com.au",
  },
  prod2022: {
    WaegApiBaseUrl: "https://api.daeg.com.au",
  },
};

export default config[EnvironmentName];
